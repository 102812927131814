import React, { useState, useRef } from 'react';
import axios from 'axios';

function App() {
    const [extractedText, setExtractedText] = useState('');
    const [isCameraActive, setIsCameraActive] = useState(false);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    const startCamera = async () => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            alert('Camera access is not supported in your browser.');
            return;
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            videoRef.current.srcObject = stream;
            setIsCameraActive(true);
        } catch (error) {
            alert('Failed to access the camera. Please grant camera permissions.');
        }
    };

    const captureImage = async () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        
        canvas.toBlob(async (blob) => {
            const formData = new FormData();
            formData.append('image', blob);

            try {
                const response = await axios.post('http://localhost:5000/api/extract-text', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                setExtractedText(response.data.extractedText);
            } catch (error) {
                alert('Error extracting text: ' + error.response.data.error);
            }
        });
    };

    return (
        <div className="App">
            <h1>OCR Application</h1>
            {!isCameraActive ? (
                <button onClick={startCamera}>Start Camera</button>
            ) : (
                <div>
                    <video ref={videoRef} autoPlay style={{ width: '300px', height: 'auto' }} />
                    <canvas ref={canvasRef} style={{ display: 'none' }} width="640" height="480"></canvas>
                    <button onClick={captureImage}>Capture Image</button>
                </div>
            )}
            {extractedText && (
                <div>
                    <h2>Extracted Text:</h2>
                    <p>{extractedText}</p>
                </div>
            )}
        </div>
    );
}

export default App;
